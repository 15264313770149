/* W700 - BOLD H1 & H2 */
.bold-w700-h16 {
  font-weight: 700;
  font-size: 16px;
}

.bold-w700-h20 {
  font-weight: 700;
  font-size: 20px;
}

.bold-w700-h20-hover:hover {
  font-weight: 700;
  font-size: 20px;
}

.bold-w700-h24 {
  font-weight: 700;
  font-size: 24px;
}

.bold-w700-h32 {
  font-weight: 700;
  font-size: 32px;
}

.bold-w700-h36 {
  font-weight: 700;
  font-size: 36px;
}

.bold-w700-h40 {
  font-weight: 700;
  font-size: 40px;
}

/* W600 - SEMIBOLD B1 */
.semibold-w600-h12 {
  font-weight: 600;
  font-size: 12px;
}

.semibold-w600-h14 {
  font-weight: 600;
  font-size: 14px;
}

.semibold-w600-h14-link {
  font-weight: 600;
  font-size: 14px;
  text-decoration: underline;
}

.semibold-w600-h16 {
  font-weight: 600;
  font-size: 16px;
}

.semibold-w600-h20 {
  font-weight: 600;
  font-size: 20px;
}

.semibold-w600-h24 {
  font-weight: 600;
  font-size: 24px;
}

.semibold-w600-h36 {
  font-weight: 600;
  font-size: 36px;
}

/* W400 - REGULAR H1 & H2 */
.regular-w400-h14 {
  font-weight: 400;
  font-size: 14px;
}

.regular-w400-h16 {
  font-weight: 400;
  font-size: 16px;
}

.regular-w400-h20 {
  font-weight: 400;
  font-size: 20px;
}

.regular-w400-h24 {
  font-weight: 400;
  font-size: 24px;
}

.regular-w400-h32 {
  font-weight: 400;
  font-size: 32px;
}

.regular-w400-h36 {
  font-weight: 400;
  font-size: 36px;
}
