/* MAIN COLORS */
.mainBackgroundColorF1 {
    background: var(--colorF1)
}

.mainBackgroundColorF2 {
    background: var(--colorF2)
}

.mainBackgroundColorF3 {
    background: var(--colorF3)
}

.mainBackgroundColorC1 {
    background: var(--colorC1)
}

.mainBackgroundColorC2 {
    background: var(--colorC2)
}

.mainBackgroundColorC3 {
    background: var(--colorC3)
}


/* DEGREES */
.degreeBackgroundsDegreeF {
    color: var(--degreeF)
}

.degreeBackgroundsDegreeC {
    color: var(--degreeC)
}

.degreeBackgroundsDegreeBlack {
    color: var(--degreeBlack)
}

/* BACKGROUNDS */
.backgroundLight {
    background: var(--backgroundLight)
}

.backgroundDark {
    background: var(--backgroundDark)
}

.backgroundB2B {
    background: var(--backgroundB2B)
}

.backgroundHome {
    background: var(--backgroundHome)
}


/* ACTIONS */
.actionsBackgroundInformative {
    background: var(--informative)
}

.actionsBackgroundPositive {
    background: var(--positive)
}

.actionsBackgroundPositiveC {
    background: var(--positiveC)
}

.actionsBackgroundNegative {
    background: var(--negative)
}

.actionsBackgroundNegativeC {
    background: var(--negativeC)
}

.actionsBackgroundWarning {
    background: var(--warning)
}