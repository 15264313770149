/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* styles.scss */

$primary: rgb(227, 253, 57);
$secondary: #6c757d;
$contrast: white;
$disabled: rgb(243, 243, 243);

:root {
  --primary: #{$primary};
  --secondary: #{$secondary};
  --contrast: #{$contrast};
  --disabled: #{$disabled};
  --primary-backgorund: #1a1a1a;
  --secondary-background: #242424;
  --secondary-background: #242424;
  --unfocused: #cccccc;
}

@font-face {
  font-family: "Blinker";
  src: url("/assets/fonts/Blinker-Regular.ttf");
}

.text-back {
  font-family: "Blinker";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.11999999731779099px;
  text-align: left;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.new-item-button-1 {
  background-color: transparent;
  position: absolute;
  right: 0;
  border: 2px var(--colorF1) solid;
  border-radius: 16px;
  color: var(--colorF1);
  img {
    width: 24px;
    height: 24px;
  }
}

hr.divider {
  width: 100%;
  height: 2px;
  color: #728197 !important;
  opacity: 1 !important;
}
