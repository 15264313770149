@import url('https://fonts.googleapis.com/css2?family=Blinker:wght@100;200;300;400;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: "Blinker", sans-serif;
}

:root {
    /* MAIN COLORS */
    --colorF1: #E3FD39;
    --colorF2: #F722A3;
    --colorF3: #A09CFF;
    --colorC1: #6CB1FF;
    --colorC2: #627EE8;
    --colorC3: #8C61E7;

    /* DEGREES */
    --degreeF: linear-gradient(to right, #E3FD39, #E5E942, #ECB55B, #F66383, #FF22A3, #A09CFF);
    --degreeC: linear-gradient(to right, #627EE8, #6CB1FF, #8C61E7);
    --degreeBlack: linear-gradient(to right, #000000, #0C1B24);

    /* TEXTS */
    --black: #191919;
    --black2: #253341;
    --blacGrey: #728197;
    --grey: #CCCCCC;
    --whiteGrey: #F3F3F3;
    --white: #FFFFFF;

    /* BACKGROUNDS */
    --backgroundLight: #FFFFFF;
    --backgroundDark: #0C1B24;
    --backgroundB2B: #1A1A1A;
    --backgroundHome: #242424;

    /* ACTIONS */
    --informative: #3ABFF8;
    --positive: #20853B;
    --positiveC: #22B07D;
    --negative: #B1000F;
    --negativeC: #FB4141;
    --warning: #FBBD23;

    /* DESIGN SET ON FIRST DESIGN */
    --primary-backgorund: #1A1A1A;
}

.text-back {
    font-family: 'Blinker';
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.11999999731779099px;
    text-align: left;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}


.text-grey {
    color: var(--grey);
}

.error-message {
    margin-top: 5px;
    font-family: Blinker;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.12px;
    text-align: left;
    color: #e3fd39;
    display: flex;
    gap: 0.5rem;
    align-items: flex-end;
    position: relative;
    left: 1rem;
}