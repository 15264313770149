/* *** MAIN COLORS *** */
.mainTextColorF1 {
    color: var(--colorF1)
}

.mainTextColorF2 {
    color: var(--colorF2)
}

.mainTextColorF3 {
    color: var(--colorF3)
}

.mainTextColorC1 {
    color: var(--colorC1)
}

.mainTextColorC2 {
    color: var(--colorC2)
}

.mainTextColorC3 {
    color: var(--colorC3)
}


/* *** DEGREES *** */
.degreeTextDegreeF {
    color: var(--degreeF)
}

.degreeTextDegreeC {
    color: var(--degreeC)
}

.degreeTextDegreeBlack {
    color: var(--degreeBlack)
}


/* *** TEXTS *** */
.textBlack {
    color: var(--black)
}

.textBlac2 {
    color: var(--blac2)
}

.textBlacGrey {
    color: var(--blacGrey)
}

.textGrey {
    color: var(--grey)
}

.textWhiteGrey {
    color: var(--whiteGrey)
}

.textWhite {
    color: var(--white)
}


/* *** ACTIONS *** */
.actionsTextInformative {
    background: var(--informative)
}

.actionsTextPositive {
    background: var(--positive)
}

.actionsTextPositiveC {
    background: var(--positiveC)
}

.actionsTextNegative {
    background: var(--negative)
}

.actionsTextNegativeC {
    background: var(--negativeC)
}

.actionsTextWarning {
    background: var(--warning)
}